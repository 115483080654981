import React, { useRef, useState } from 'react';
import { BsLinkedin, BsTelegram } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import emailjs from '@emailjs/browser';

// import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ username: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  // eslint-disable-next-line
  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Send email
    emailjs
      .sendForm(
        'service_gtu4hmb', 
        'template_kkbk50x', 
        form.current, 
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setLoading(false);
          setIsFormSubmitted(true);
        },
        (error) => {
          console.log('Failed to send email:', error);
          setLoading(false);
        }
      );

    // Create contact in your database
    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        console.log('Contact created in database');
      })
      .catch((err) => console.log('Error creating contact:', err));
  };

  return (
    <>
      <h2 className="head-text">Take a coffee & chat with me</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <MdEmail style={{ fontSize: '36px', marginRight: '8px' }} />
          <a href="mailto:web3arpit@gmail.com" className="p-text">web3arpit@gmail.com</a>
        </div>
     
        <div className="app__footer-card">
          <BsTelegram style={{ fontSize: '36px', marginRight: '8px' }} />
          <a href="https://t.me/arpitweb3" target="_blank" rel="noopener noreferrer" className="p-text">Telegram</a>
        </div>

        <div className="app__footer-card">
          <BsLinkedin style={{ fontSize: '36px', marginRight: '8px' }} />
          <a href="https://www.linkedin.com/in/arpitweb3" target="_blank" rel="noopener noreferrer" className="p-text">arpitweb3</a>
        </div>


      </div>
      {!isFormSubmitted ? (
        <form ref={form} onSubmit={handleSubmit} className="app__footer-form app__flex">
          <div className="app__flex">
            <input 
              className="p-text" 
              type="text" 
              placeholder="Your Name" 
              name="username" 
              value={formData.username} 
              onChange={handleChangeInput} 
            />
          </div>
          <div className="app__flex">
            <input 
              className="p-text" 
              type="email" 
              placeholder="Your Email" 
              name="email" 
              value={formData.email} 
              onChange={handleChangeInput} 
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              name="message"
              value={formData.message}
              onChange={handleChangeInput}
            />
          </div>
          <button type="submit" className="p-text">
            {!loading ? 'Send Message' : 'Sending...'}
          </button>
        </form>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);