import React from "react";
import { BsTwitterX } from "react-icons/bs";

import { FaGithub } from "react-icons/fa6";
import { GrLinkedin } from "react-icons/gr";
import { FaTelegram } from "react-icons/fa"; // Add this import

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a href="https://twitter.com/arpitweb3">
        {" "}
        <div className="cursor-pointer ">
          <BsTwitterX />
        </div>{" "}
      </a>

      <a href="https://www.linkedin.com/in/arpitweb3/">
        <div className="cursor-pointer">
          <GrLinkedin />
        </div>{" "}
      </a>

      <a href="https://github.com/arpit3210">
        {" "}
        <div className="cursor-pointer">
          <FaGithub />
        </div>{" "}
      </a>

      <a href="https://t.me/arpitweb3"> 
        <div className="cursor-pointer">
          <FaTelegram />
        </div>
      </a>
    </div>
  );
};

export default SocialMedia;
